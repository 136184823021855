import { Link } from "react-router-dom";
import "./../reports/report-nav.css";
import { BsCollection } from "react-icons/bs";
import { VscActivateBreakpoints } from "react-icons/vsc";
import { useState } from "react";
import { useEffect } from "react";
const ReportNav = () => {
  var url = window.location.href;
  url = url.replace(/^.*\/\/[^\/]+/, "");
  const [open, setOpen] = useState(false);
  const [forex, setForex] = useState(false);
  const [openaudit, setOpenaudit] = useState(false);
  return (
    <>
      {localStorage.getItem("client") !== "SP Madrid" &&
      localStorage.getItem("client") !== "Maya Bank Collections" ? (
        <div
          className={`side-panel ${
            url === "/reports/dashboard" ? "h-[666px]" : ""
          }`}
        >
          <nav className="secondary-menu">
            {localStorage.getItem("client") === "DBS Bank Singapore" ? (
              <ul>
                <li
                  className=" list-none"
                  style={{
                    marginLeft: "30px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => setOpen(!open)}
                >
                  <i
                    className={`${
                      !open ? "fa fa-arrow-right" : "fa fa-arrow-right click"
                    } text-3xl`}
                    aria-hidden="true"
                  ></i>

                  <a style={{ fontSize: "16px", fontWeight: "bold" }}>Trade</a>
                </li>
                {open && (
                  <ul style={{ marginLeft: "20px", transition: "ease-in" }}>
                    <li className={url === "/reports/forex" ? "active" : ""}>
                      <Link to="/reports/forex">
                        <i class="fa fa-money" aria-hidden="true"></i>
                        <a style={{ fontSize: "14px" }}>Forex</a>
                      </Link>
                    </li>
                    <li
                      className={
                        url === "/reports/equity" ? "active" : "disabled"
                      }
                    >
                      <Link to="/reports/equity">
                        <i class="fa fa-line-chart" aria-hidden="true"></i>
                        <a style={{ fontSize: "14px" }}>Equity</a>
                      </Link>
                    </li>
                    <li
                      className={
                        url === "/reports/mutual" ? "active" : "disabled"
                      }
                    >
                      <Link to="/reports/mutual">
                        <i class="fa fa-users" aria-hidden="true"></i>{" "}
                        <a style={{ fontSize: "14px" }}>Mutual Funds</a>
                      </Link>
                    </li>
                  </ul>
                )}
                {localStorage.getItem("role") === "Master" ? (
                  <li
                    className={
                      url === "/reports/agent-scorecard" ? "active" : ""
                    }
                  >
                    <Link to="/reports/agent-scorecard">
                      <i className="fa fa-superpowers" aria-hidden="true"></i>
                      <a style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Agent Scorecard
                      </a>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            ) : (
              ""
            )}

            {localStorage.getItem("usecase") === "Complaints Management" ? (
              <ul>
                <li
                  className={url === "/reports/service-request" ? "active" : ""}
                >
                  <div className=" flex items-center">
                    <Link to="/reports/service-request">
                      <i className="fa fa-superpowers" aria-hidden="true"></i>
                      <a style={{ fontSize: "14px" }}> Incoming Complaints </a>
                    </Link>
                  </div>
                </li>
                <li
                  className={url === "/reports/customer-intent" ? "active" : ""}
                >
                  <Link to="/reports/customer-intent">
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Intent Trend</a>
                  </Link>
                </li>
                <li
                  className={url === "/reports/product-intent" ? "active" : ""}
                >
                  <Link to="/reports/product-intent">
                    <i className="flaticon-analytics"></i>
                    <a style={{ fontSize: "14px" }}>Sentiments Trends</a>
                  </Link>
                </li>

                {/* for dbs bank dropdown  */}

                <li
                  className={url === "/reports/cx-score-trend" ? "active" : ""}
                >
                  <Link to="/reports/cx-score-trend">
                    <i className="fa fa-line-chart" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>
                      Complaints Intent Trends{" "}
                    </a>
                  </Link>
                </li>
                <li
                  className={url === "/reports/manager-review" ? "active" : ""}
                >
                  <Link to="/reports/manager-review">
                    <i className="fa fa-comment" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Manager Review</a>
                  </Link>
                </li>

                <li className={url === "/reports/opportunity" ? "active" : ""}>
                  <Link to="#">
                    <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Reopened by DataKlout</a>
                  </Link>
                </li>

                {localStorage.getItem("collection_module") === "true" && (
                  <li className={url === "/reports/collection" ? "active" : ""}>
                    <Link to="#">
                      <span className=" flex items-center">
                        <BsCollection />

                        <a style={{ fontSize: "14px" }}>Customer Survey</a>
                      </span>
                    </Link>
                  </li>
                )}

                <li className={url === "/reports/performance" ? "active" : ""}>
                  <Link to="#">
                    <span className=" flex items-center">
                      <VscActivateBreakpoints />

                      <a style={{ fontSize: "14px" }}>Outlier Case Analysis</a>
                    </span>
                  </Link>
                </li>

                {localStorage.getItem("stock_broking_module") === "true" && (
                  <li className={url === "/reports/compliance" ? "active" : ""}>
                    <Link to="/reports/compliance">
                      <VscActivateBreakpoints />
                      <a style={{ fontSize: "14px" }}>Compliance </a>
                    </Link>
                  </li>
                )}
              </ul>
            ) : localStorage.getItem("client") !== "DBS Bank Singapore" &&
              localStorage.getItem("usecase") !==
                "Insurance - PCVC Verification" ? (
              <ul>
                <li
                  className={url === "/reports/service-request" ? "active" : ""}
                >
                  <Link to="/reports/service-request">
                    <i className="fa fa-superpowers" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Service Request</a>
                  </Link>
                </li>
                {localStorage.getItem("role") === "Master" ? (
                  <li
                    className={
                      url === "/reports/agent-scorecard" ? "active" : ""
                    }
                  >
                    <Link to="/reports/agent-scorecard">
                      <i className="fa fa-superpowers" aria-hidden="true"></i>
                      <a style={{ fontSize: "14px" }}>Agent Scorecard</a>
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                <li
                  className={url === "/reports/customer-intent" ? "active" : ""}
                >
                  <Link to="/reports/customer-intent">
                    <i className="fa fa-tachometer" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}> Customer Intent</a>
                  </Link>
                </li>
                <li
                  className={url === "/reports/product-intent" ? "active" : ""}
                >
                  <Link to="/reports/product-intent">
                    <i className="flaticon-analytics"></i>
                    <a style={{ fontSize: "14px" }}>Product by Intent</a>
                  </Link>
                </li>

                {/* for dbs bank dropdown  */}

                <li
                  className={url === "/reports/cx-score-trend" ? "active" : ""}
                >
                  <Link to="/reports/cx-score-trend">
                    <i className="fa fa-line-chart" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>CX Score Trend</a>
                  </Link>
                </li>
                <li
                  className={url === "/reports/manager-review" ? "active" : ""}
                >
                  <Link to="/reports/manager-review">
                    <i className="fa fa-comment" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Manager Review</a>
                  </Link>
                </li>
                <li className={url === "/reports/opportunity" ? "active" : ""}>
                  <Link to="/reports/opportunity">
                    <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                    {localStorage.getItem("usecase") ===
                    "Third Party Collection Agency" ? (
                      <a style={{ fontSize: "14px" }}>Promise To Pay </a>
                    ) : (
                      <a style={{ fontSize: "14px" }}>Opportunities</a>
                    )}
                  </Link>
                </li>

                {localStorage.getItem("collection_module") === "true" && (
                  <li className={url === "/reports/collection" ? "active" : ""}>
                    <Link to="/reports/collection">
                      <span className=" flex items-center">
                        <BsCollection />

                        <a style={{ fontSize: "14px" }}>Collection</a>
                      </span>
                    </Link>
                  </li>
                )}
                {localStorage.getItem("collection_module") === "true" && (
                  <li
                    className={url === "/reports/performance" ? "active" : ""}
                  >
                    <Link to="/reports/performance">
                      <span className=" flex items-center">
                        <VscActivateBreakpoints />

                        <a style={{ fontSize: "14px" }}>Performance</a>
                      </span>
                    </Link>
                  </li>
                )}
                {localStorage.getItem("stock_broking_module") === "true" && (
                  <li className={url === "/reports/compliance" ? "active" : ""}>
                    <Link to="/reports/compliance">
                      <VscActivateBreakpoints />
                      <a style={{ fontSize: "14px" }}>Compliance </a>
                    </Link>
                  </li>
                )}
              </ul>
            ) : localStorage.getItem("usecase") ===
              "Insurance - PCVC Verification" ? (
              <>
                <ul>
                  <li
                    className={
                      url === "/reports/dashboard"
                        ? "active flex items-center"
                        : ""
                    }
                  >
                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    <Link to="/reports/dashboard">
                      <a style={{ fontSize: "14px", paddingLeft: "0px" }}>
                        Dashboard
                      </a>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li
                    className=""
                    // style={{ marginLeft: "30px" }}
                    onClick={() => setOpenaudit(!openaudit)}
                  >
                    <div className=" flex items-center">
                      <i
                        className={`${
                          !openaudit
                            ? "fa fa-arrow-right"
                            : "fa fa-arrow-right click"
                        }`}
                        aria-hidden="true"
                      ></i>

                      <a style={{ fontSize: "14px" }}>Audit</a>
                    </div>
                    {openaudit && (
                      <ul
                        style={{ fontSize: "10px", transition: "ease-in" }}

                        // id="audit-nav"
                      >
                        <li
                          className={
                            url === "/reports/criticalBusiness" ? "active" : ""
                          }
                          onClick={() => setOpenaudit(!openaudit)}
                        >
                          <Link to="/reports/criticalBusiness">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            <a style={{ fontSize: "14px" }}>Business</a>
                          </Link>
                        </li>
                        <li
                          className={
                            url === "/reports/criticalcustomer" ? "active" : ""
                          }
                        >
                          <Link to="/reports/criticalcustomer">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>
                            <a style={{ fontSize: "14px" }}>Customer </a>
                          </Link>
                        </li>
                        <li
                          className={
                            url === "/reports/criticalregulatory"
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="/reports/criticalregulatory">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                            <a
                              style={{
                                fontSize: "14px",
                              }}
                              id="audit-icon"
                            >
                              Regulatory{" "}
                            </a>
                          </Link>
                        </li>
                        <li
                          className={
                            url === "/reports/noncritical" ? "active" : ""
                          }
                        >
                          <Link to="/reports/noncritical">
                            <i class="fa fa-arrow-right" aria-hidden="true"></i>{" "}
                            <a style={{ fontSize: "14px" }}>Non Critical</a>
                          </Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  {localStorage.getItem("role") === "Master" ? (
                    <li
                      className={
                        url === "/reports/agent-scorecard" ? "active" : ""
                      }
                    >
                      <Link to="/reports/agent-scorecard">
                        <i className="fa fa-superpowers" aria-hidden="true"></i>
                        <a style={{ fontSize: "14px" }}>Agent Scorecard</a>
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </>
            ) : (
              ""
            )}
          </nav>
        </div>
      ) : localStorage.getItem("client") === "Maya Bank Collections" ? (
        <div
          className={`side-panel ${
            url === "/reports/dashboard" ? "h-[666px]" : ""
          }`}
        >
          <nav className="secondary-menu  marker:bg-none">
            <ul>
              <li className={url === "/reports" ? "active" : ""}>
                <Link to="/reports/promise-to-pay">
                  <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                  <a style={{ fontSize: "14px" }}>Report</a>
                </Link>
              </li>
              {localStorage.getItem("role") === "Master" ? (
                <li
                  className={url === "/reports/agent-scorecard" ? "active" : ""}
                >
                  <Link to="/reports/agent-scorecard">
                    <i className="fa fa-superpowers" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Agent Scorecard</a>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
      ) : (
        <div
          className={`side-panel ${
            url === "/reports/dashboard" ? "h-[666px]" : ""
          }`}
        >
          <nav className="secondary-menu  marker:bg-none">
            <ul>
              <li className={url === "/reports/promise-to-pay" ? "active" : ""}>
                <Link to="/reports/promise-to-pay">
                  <i className="fa fa-snowflake-o" aria-hidden="true"></i>
                  <a style={{ fontSize: "14px" }}>Promise To Pay </a>
                </Link>
              </li>
              <li className={url === "/reports/collection" ? "active" : ""}>
                <Link to="/reports/collection">
                  <span className=" flex items-center">
                    <BsCollection />

                    <a style={{ fontSize: "14px" }}>Collection</a>
                  </span>
                </Link>
              </li>
              {localStorage.getItem("role") === "Master" ? (
                <li
                  className={url === "/reports/agent-scorecard" ? "active" : ""}
                >
                  <Link to="/reports/agent-scorecard">
                    <i className="fa fa-superpowers" aria-hidden="true"></i>
                    <a style={{ fontSize: "14px" }}>Agent Scorecard</a>
                  </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};

export default ReportNav;
