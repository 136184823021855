import { useHistory } from "react-router-dom";
import Service from "./../webservice/http";
// import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ChatButton from "../chat-bot/chat-button";
import { BsChatLeftText, BsQuestionCircle } from "react-icons/bs";
// import ReactTimeAgo from 'react-time-ago'
// import TimeAgo from 'javascript-time-ago'
// import en from 'javascript-time-ago/locale/en.json';

import ChatList from "./../chat/chat-list";
import ChatBox from "./../chat/chat-box";

import React, { useState, useCallback, useEffect } from "react";
import useWebSocket, { ReadyState } from "react-use-websocket";

const Header = () => {
  // TimeAgo.addDefaultLocale(en)
  // TimeAgo.addLocale(ru)

  const history = useHistory();
  const services = new Service();

  /**
   * Logout Method
   * All local Storage will be cleared and call logout api to blacklist the token
   */
  const logout = () => {
    services.get("api/access_control/logout/").then((res) => {
      // console.log(res);
    });
    localStorage.clear();
    history.push("/login");
    window.location.reload();
    //browser.tabs.reload();
  };

  const [chatHeaders, setChatHeaders] = useState(null);

  /**
   * Fetch self profile details
   * Store all info in localstorage
   */
  function fetchProfileData() {
    services.get("api/access_control/self_details/").then((res) => {
      if (res == "TypeError: Failed to fetch") {
      } else {
        try {
          if (res.code == "token_not_valid") {
            localStorage.clear();
            history.push("/login");
          }
          localStorage.setItem("client_name", res["client"]);
          localStorage.setItem("first_name", res["first_name"]);
          localStorage.setItem("image", res["image"]);
          localStorage.setItem("last_name", res["last_name"]);
          localStorage.setItem("role", res["role"]);
        } catch (e) {}
      }
    });
  }

  useEffect(() => {
    fetchProfileData();
    // fetchChatHeadres();
  }, []);

  /**
   * Reload the page when there is any change in Localstoage
   * It is useful when application is logged out from one window another tab is opened
   */
  useEffect(() => {
    window.addEventListener("storage", () => {
      window.location.reload();
    });
  }, []);

  const [showChatBox, setShowChatBox] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    username: "",
    id: "",
    image: "",
  });

  const [messageData, setMessageData] = useState(null);

  const [disclamair, setDisclamair] = useState(false);

  // ______________________________________________________________________________________________________________________________

  const [socketUrl, setSocketUrl] = useState(
    `ws://129.154.245.60:8030/chat/?${localStorage.getItem("access_token")}`
  );

  const [messageHistory, setMessageHistory] = useState([]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  const [tempMessageData, setTempMessageData] = useState(null);
  const [typing, setTyping] = useState(false);

  /**
   * Track typing status in one to one chat
   */
  useEffect(() => {
    if (typing === true) {
      const timeId = setTimeout(() => {
        setTyping(false);
      }, 1000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [typing]);

  /**
   * Handle new message stream send by peer
   */
  useEffect(() => {
    if (lastMessage !== null) {
      var receivedData = JSON.parse(lastMessage.data);
      var data = messageData;

      // console.log(receivedData);

      var i = 0;
      // var count = data.length;
      // for (let i = 0; i < data.length; i++) {
      if (receivedData.typing == true) {
        // console.log(selectedUser.id);
        // console.log(receivedData.sent_by);
        if (selectedUser.id == receivedData.sent_by) {
          setTyping(true);
        }
      } else {
        messageData.map(() => {
          if (
            data[i].username === receivedData.sent_by ||
            data[i].username === receivedData.sent_to
          ) {
            data[i].last_message = receivedData.message;
            data[i].last_message_by = receivedData.sent_by;
            data[i].messages.push({
              date: "",
              time: "",
              username: receivedData.sent_by,
              _id: "",
              _message: receivedData.message,
              _seen: false,
            });
          }
          i++;
        });
        // console.log(messageData);
        setTempMessageData((p) => data);
      }
    }
  }, [lastMessage, messageData]);

  useEffect(() => {
    if (tempMessageData !== null) {
      setMessageData((p) => tempMessageData);
      setTempMessageData(null);
    }
  }, [tempMessageData]);

  const [sendMsgData, setSendMsgData] = useState({});
  const handleClickSendMessage = useCallback(() => {
    // console.log(JSON.stringify(sendMsgData));
    sendMessage(JSON.stringify(sendMsgData));
  }, []);

  useEffect(() => {
    // console.log(sendMsgData);
    sendMessage(JSON.stringify(sendMsgData));
  }, [sendMsgData]);

  /**
   * React websocket status config
   */
  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return (
    <div className="top-header-botttom clearfix p-2">
      <ChatButton />

      {showChatBox && (
        <ChatBox
          setShowChatBox={setShowChatBox}
          selectedUser={selectedUser}
          messageData={messageData}
          setMessageData={setMessageData}
          setSendMsgData={setSendMsgData}
          handleClickSendMessage={handleClickSendMessage}
          typing={typing}
        />
      )}
      <div
        className="container-fluid"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="logo">
          <a href="/">
            <img
              src="/assets/images/logo-icon-1.png"
              className="img-responsive"
              alt=""
            />
          </a>
        </div>
        <div className="top-header-botttom-right">
          <div className="top-header-botttom-right-left">
            <div className="search-bar">
              <select>
                <option value="0">All</option>
              </select>
              <div className="search-bar-box">
                <input type="text" name="search" placeholder="Search..." />
                <button type="submit">
                  <i className="icon-Search"></i>
                </button>
              </div>
            </div>
            <div className="wrapper-option">
              {/* <div className="dropdown left">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {" "}
                  <i className="icon-Fav"></i>
                  Favourites
                </button>
              </div> */}

              {/* <div className=" left last">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {" "}
                  <i className="icon-Action"></i>
                  Actions
                </button>
              </div> */}
            </div>
          </div>
          <div className="top-header-botttom-right-wrapper-right">
            <ul style={{ marginBottom: "0px" }}>
              <li
                className="dropdown left last"
                onClick={() => setDisclamair(true)}
              >
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {" "}
                  <BsQuestionCircle />{" "}
                </button>
              </li>
              <li className="dropdown left last">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {" "}
                  <BsChatLeftText />{" "}
                </button>
                <ul className="dropdown-menu">
                  <ChatList
                    setShowChatBox={setShowChatBox}
                    setSelectedUser={setSelectedUser}
                    messageData={messageData}
                    setMessageData={setMessageData}
                  />
                </ul>
              </li>

              <li className="dropdown left last">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {localStorage.getItem("client_logo") != "/media/" && (
                    <img
                      src={
                        services.domain + localStorage.getItem("client_logo")
                      }
                      style={{
                        width: "60px",
                        height: "40px",
                        // borderRadius: "50%",
                        // marginBottom: "10px",
                      }}
                    />
                  )}
                </button>
              </li>

              <li className="dropdown left last">
                <button
                  className="btn btn-primary dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  {localStorage.getItem("image") === "/media/" && (
                    <i className="icon-user"></i>
                  )}
                  {localStorage.getItem("image") != "/media/" && (
                    <img
                      src={services.domain + localStorage.getItem("image")}
                      style={{
                        width: "45px",
                        height: "45px",
                        borderRadius: "50%",
                        // marginBottom: "10px",
                      }}
                    />
                  )}
                </button>

                <ul
                  className="dropdown-menu mt-4"
                  style={{ padding: "14px 0px" }}
                >
                  <li>
                    <Link to="/account">
                      <span className=" flex items-center mb-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-person-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                          <path
                            fill-rule="evenodd"
                            d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                          />
                        </svg>
                        <a className=" ml-4" style={{ fontSize: "16px" }}>
                          {localStorage.getItem("first_name")}&nbsp;
                          {localStorage.getItem("last_name")}
                        </a>
                      </span>
                    </Link>
                  </li>
                  {/* <li>
                    <a>{localStorage.getItem("client_name")}</a>
                  </li> */}
                  <li className=" ">
                    <Link>
                      <span
                        className=" flex flex-row items-center cursor-pointer "
                        onClick={logout}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-box-arrow-right"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                          />
                          <path
                            fill-rule="evenodd"
                            d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                          />
                        </svg>
                        <a className=" ml-4" style={{ fontSize: "16px" }}>
                          Logout
                        </a>
                      </span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {disclamair && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-500 bg-opacity-50 z-50 overflow-y-auto">
          <div className="bg-white p-10 rounded-lg shadow-lg w-[400px] h-[200px]">
            <h2 className=" font-bold mb-4 text-2xl ">Dataklout</h2>
            <p className="mb-4 text-2xl">
              For any Querries contact{" "}
              <span className=" text-blue-900 font-semibold">
                support@dataklout.com
              </span>
            </p>
            <div className="flex justify-end mt-[60px]">
              <button
                className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                onClick={() => setDisclamair(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
