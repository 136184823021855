import Login from "./auth/login";
import CheckLoginStatus from "./auth/check-login-status";
import CallList from "./call-list";
import Home from "./home";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import CallInsight from "./call-insight";
import Leads from "./opportunities";
import ServiceRequests from "./service-requests";
import ArchiveCall from "./archive-call";
import DeepAnalysis from "./deep-analysis";
import Contact from "./contact";
import LeadDetails from "./opportunity-details";
import SRDetails from "./service-request-details";
import ManageUser from "./user-management/manage-user";
import ManageRole from "./user-management/manage-role";
import AppFeature from "./user-management/app-feature";
import CustomerIntentReport from "./reports/customer-intent";
import ProductIntentReport from "./reports/product-intent";
import CXScoreTrendReport from "./reports/cx-score-trend";
import ManagerReviewReport from "./reports/mamager-review";
import OpportunityReport from "./reports/opportunity";
import ServiceRequestReport from "./reports/service-request";
import CollectionReport from "./reports/collection";
import Performance from "./reports/performance";
import Task from "./task";
import ContactDetail from "./contact-detail";
import LiveCall from "./live-call";
import Account from "./account";
import QualityHome from "./quality/quality-home";
import DashboardHome from "./reports/dashboard/home";
import ComplianceReport from "./reports/compliance";
// import Trade from "./reports/trade";
// import Forex from "./reports/forex";
import Equity from "./reports/equity";
import Mutual from "./reports/mutual";
import Forex from "./reports/forex";
import Criticalbusiness from "./reports/audit/criticalbusiness";
import Criticalcustomer from "./reports/audit/criticalcustomer";
import Criticalregulatory from "./reports/audit/criticalregulatory";
import Outputdatabase from "./reports/audit/outputdatabase";
import Noncritical from "./reports/audit/noncritical";
import PageNotFound from "./pagenotfound";
import ComplaintsInsight from "./complaintsManagement/complaintsInsight";
import ComplaintsDeepAnalysis from "./complaintsManagement/complaintDeepAnalysis";
import TicketList from "./complaintsManagement/ticketlist";
import ComplaintsServiceRequest from "./complaintsManagement/complaintsServiceRequest";
import ComplaintsArchived from "./complaintsManagement/complaintsArchived";
import Report from "./reports/mayaBank/report";
import Promisetopay from "./promisetopay/promisetopay";
import Promispay from "./reports/promistopay";
import Agentscorecard from "./reports/agent-scorecard";
import AgentInsight from "./reports/agent-insight";

function App() {
  /**
   * Application Initialization Point
   *
   * Navigate to different Components based on router link
   */
  const { loginStatus } = CheckLoginStatus();
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <Home loginStatus={loginStatus} />
          </Route>
          <Route exact path="/login">
            <Login loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call-list">
            <CallList loginStatus={loginStatus} />
          </Route>
          <Route exact path="/ticket-list">
            <TicketList loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/:callID/call-insight">
            <CallInsight loginStatus={loginStatus} />
          </Route>
          <Route exact path="/ticket/:callID/complaints-insight">
            <ComplaintsInsight loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/:callID/deep-analysis">
            <DeepAnalysis loginStatus={loginStatus} />
          </Route>
          <Route exact path="/ticket/:callID/deep-analysis">
            <ComplaintsDeepAnalysis loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/opportunities">
            <Leads loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/promise-to-pay">
            <Promisetopay loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/:leadID/opportunity-details">
            <LeadDetails loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/:leadID/promise-details">
            <LeadDetails loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/service-requests">
            <ServiceRequests loginStatus={loginStatus} />
          </Route>
          <Route exact path="/ticket/service-requests">
            <ComplaintsServiceRequest loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/:srID/sr-details">
            <SRDetails loginStatus={loginStatus} />
          </Route>
          <Route exact path="/call/archive-calls">
            <ArchiveCall loginStatus={loginStatus} />
          </Route>
          <Route exact path="/ticket/archive-tickets">
            <ComplaintsArchived loginStatus={loginStatus} />
          </Route>
          <Route exact path="/contact">
            <Contact loginStatus={loginStatus} />
          </Route>
          <Route exact path="/contact/:contactId">
            <ContactDetail loginStatus={loginStatus} />
          </Route>
          <Route exact path="/user-management/manage-user">
            <ManageUser loginStatus={loginStatus} />
          </Route>
          <Route exact path="/user-management/manage-role">
            <ManageRole loginStatus={loginStatus} />
          </Route>
          <Route exact path="/user-management/app-feature">
            <AppFeature loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/customer-intent">
            <CustomerIntentReport loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/product-intent">
            <ProductIntentReport loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/forex">
            <Forex />
          </Route>
          <Route exact path="/reports/equity">
            <Equity />
          </Route>
          <Route exact path="/reports/mutual">
            <Mutual />
          </Route>
          {/* audit route below */}
          {/* /reports/ /reports/ /reports/criticalregulatory /reports/ */}
          <Route exact path="/reports/agent-scorecard/:id">
            <AgentInsight />
          </Route>
          <Route exact path="/reports/agent-scorecard">
            <Agentscorecard />
          </Route>
          <Route exact path="/reports/criticalbusiness">
            <Criticalbusiness />
          </Route>
          <Route exact path="/reports/criticalcustomer">
            <Criticalcustomer />
          </Route>
          <Route exact path="/reports/noncritical">
            <Noncritical />
          </Route>
          <Route exact path="/reports/criticalregulatory">
            <Criticalregulatory />
          </Route>
          <Route exact path="/reports/dashboard">
            <Outputdatabase />
          </Route>
          <Route exact path="/reports">
            <Report />
          </Route>
          <Route exact path="/reports/promise-to-pay">
            <Promispay loginStatus={loginStatus} />
          </Route>
          {/* route ends here */}
          <Route exact path="/reports/cx-score-trend">
            <CXScoreTrendReport loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/manager-review">
            <ManagerReviewReport loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/opportunity">
            <OpportunityReport loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/service-request">
            <ServiceRequestReport loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/collection">
            <CollectionReport loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/performance">
            <Performance loginStatus={loginStatus} />
          </Route>
          <Route exact path="/reports/compliance">
            <ComplianceReport loginStatus={loginStatus} />
          </Route>
          <Route exact path="/task">
            <Task loginStatus={loginStatus} />
          </Route>
          <Route exact path="/live-call">
            <LiveCall />
          </Route>
          <Route exact path="/account">
            <Account />
          </Route>
          <Route exact path="/quality-audit">
            <QualityHome />
          </Route>
          <Route exact path="/dashboard">
            <DashboardHome />
          </Route>
          <Route path="*" component={PageNotFound} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
